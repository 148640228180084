import MazAvatar from "maz-ui/components/MazAvatar";
import { inject, ref, computed, watch, nextTick, watchEffect } from "vue";
import { cloneDeep, filter, find, map, some, each } from "lodash";
import { object, string, mixed } from "yup";
import { profileOpenIK, userIDIK, profileOptionsIK, accountIDIK } from "./index";
import { useStore } from "vuex";
const userObj = {
  user: {
    countryID: 1
  },
  employment: {
    permanent: true
  },
  roles: []
};

/*eslint-disable*/
export default {
  name: "UserComponent",
  components: {
    MazAvatar
  },
  data: () => ({
    hh: window.innerHeight - 100,
    ww: 1000
  }),
  setup() {
    const store = useStore();
    const API = inject("axios");
    const toast = inject("toast");
    const tabs = ref(0);
    const loading = ref(false);
    const profile = ref(cloneDeep(userObj));
    const passwordVisible = ref(false);
    const foreign = ref(false);
    const ethnicities = store.getters["lookups/ethnicities"];
    const countries = store.getters["lookups/countries"];
    const userTitles = store.getters["lookups/userTitles"];
    const countriesFetched = ref(false);
    const ethnicitiesFetched = ref(false);
    const userTitlesFetched = ref(false);
    watchEffect(() => {
      if (!ethnicities.value || ethnicities.value.length === 0 && !ethnicitiesFetched) {
        store.dispatch("lookups/fetchEthnicities");
        ethnicitiesFetched.value = true;
      }
    });
    watchEffect(() => {
      if (!countries.value || countries.value.length === 0 && !countriesFetched) {
        store.dispatch("lookups/fetchCountries");
        countriesFetched.value = true;
      }
    });
    watchEffect(() => {
      if (!userTitles.value || userTitles.value.length === 0 && !userTitlesFetched) {
        store.dispatch("lookups/fetchUserTitles");
        userTitlesFetched.value = true;
      }
    });
    const company = computed(() => store.getters["companies/getCompany"]);
    const assignableRoles = computed(() => store.getters["lookups/assignableRoles"]);
    const profileOpen = inject(profileOpenIK);
    const profileOptions = inject(profileOptionsIK);
    const userID = inject(userIDIK);
    const accountID = inject(accountIDIK);
    const userSchema = ref(object().shape({
      userRoles: mixed().required("User Type is required").nullable(),
      user: object().shape({
        accountID: mixed().nullable(),
        firstName: string().required("First Name is required").nullable(),
        lastName: string().required("Last Name is required").nullable(),
        citizenshipID: string().required("ID/Passport Number is required").nullable(),
        ethnicityID: mixed().required("Ethnicity is required").nullable(),
        gender: string().required("Gender is required").nullable(),
        mainContact: string().required("Cellphone is required").nullable(),
        email: string().email("Invalid email address").required().label("Email Address").nullable()
      })
    }));
    const userRef = ref(null);
    const physicalAddress = ref(null);
    const postalAddress = ref(null);
    watch(accountID, v => {
      if (!v) return;
      profile.value = cloneDeep(profileOptions.value?.profile);
      if (!profile.value) profile.value = cloneDeep(userObj);
      profile.value.nextStep = true;
      userRef.value?.resetForm();
    });

    /**
     * Checks the user's roles and sets the userRoles property.
     */
    const checkRoles = async () => {
      if (!profile.value?.roles) return;
      await nextTick();
      /* order list of roles codes to check */
      let rolesToCheck = map(assignableRoles.value, "code");

      /* find the first role in the list that the user has keeping in mind the order */
      let roleFound = find(rolesToCheck, role => profile.value.roles.includes(role));
      profile.value.userRoles = roleFound;
    };

    /**
     * Computes the roles to select from based on the user's profile and active user roles.
     *
     * @returns {Array} The roles to select from.
     */
    const rolesToSelectFrom = computed(() => {
      if (profile.value.roles.length == 0) {
        profile.value["roles"] = ["NEW"];
      }
      let roleCombos = {
        ADR: ["ADR"],
        DIR: ["DIR"],
        SH: ["SH"],
        EMP: ["EMP"],
        CO: ["CO", "BI"],
        BI: ["BI"],
        NEW: ["ADM", "CO"],
        US: ["ADM", "CO"]
      };
      /* selcted role combos */
      let selectedRC = ["ADM"];
      each(profile.value.roles, role => {
        selectedRC = [...selectedRC, ...(roleCombos?.[role] ?? [])];
      });
      return filter(assignableRoles.value, role => selectedRC.includes(role.code));
    });

    /**
     * Checks if the user has the specified roles.
     *
     * @param {Array} roles The roles to check.
     * @returns {Boolean} True if the user has the roles, false otherwise.
     */
    const hasRoles = (...roles) => some(roles, role => (profile.value?.roles ?? []).includes(role));
    watch(profile, checkRoles);

    /**
     * Closes the user profile and performs necessary cleanup actions.
     */
    const handleClose = () => {
      profileOpen.value = false;
      profile.value = cloneDeep(userObj);
      profile.value.nextStep = false;
      accountID.value = null;
      profileOptions.value?.close?.();
      userRef.value?.resetForm();
    };
    const attemptDateOfBirth = idNumber => {
      const dob = idNumber.substring(0, 6);
      try {
        const parsed = parse(dob, "yyMMdd", new Date());
        return format(parsed, "yyyy-MM-dd");
      } catch (error) {
        console.error({
          error
        });
        return null;
      }
    };

    /**
     * Selects a lookup item and fetches the user's profile.
     *
     * @param {Object} uuid The UUID of the selected item.
     */
    const selectLookupItem = async uuid => {
      if (uuid === false) {
        await nextTick();
        return;
      }
      if (!uuid || uuid === true) {
        await nextTick();
        profile.value.user = {};
        return;
      }
      let accountItem = find(uuid?.accounts, {
        companyID: company.value.companyID
      });
      let itemAccountID = accountItem?.accountID ?? uuid?.accountID;
      if (itemAccountID) {
        loading.value = true;
        return API.post(`/users/profile/${itemAccountID}`, {
          companyID: company.value.companyID
        }).then(({
          data
        }) => {
          profile.value = cloneDeep(data);
          profile.value.nextStep = true;
          userRef.value?.resetForm();
        }).catch(err => {
          console.error(err);
        }).finally(() => {
          loading.value = false;
        });
      }
      profile.value["user"] = {
        userID: uuid.userID,
        dateOfBirth: uuid.DOB ?? uuid.DateOfBirth ?? uuid.dateOfBirth ?? attemptDateOfBirth(uuid.IDNumber),
        accountID: uuid.accountID ?? uuid?.accounts?.[0]?.accountID,
        firstName: uuid.Firstname ?? uuid.firstName ?? uuid.FirstName,
        lastName: uuid.Lastname ?? uuid.lastName ?? uuid.Surname ?? uuid.surname,
        email: uuid.Email ?? uuid?.email,
        mainContact: uuid.ContactNumber ?? uuid.mainContact,
        citizenshipID: uuid.IDNumber ?? uuid.citizenshipID,
        physicalAddress: uuid.physicalAddress ?? uuid.address ?? uuid?.accounts?.[0]?.address ?? {
          countryID: 1
        },
        contactID: uuid.contactID ?? uuid?.accounts?.[0]?.contactID,
        gender: uuid.gender ?? uuid.Gender,
        ethnicityID: uuid.ethnicityID,
        ethnicity: uuid.ethnicity,
        countryID: uuid.countryID ?? 1
      };
      profile.value["roles"] = ["NEW"];
      profile.value["userRoles"] = "CO";
      profile.value.nextStep = true;
      userRef.value?.resetForm();
    };

    /**
     * Creates a new user.
     */
    const createNewUser = async () => {
      if (!profile.value.user.dateOfBirth) {
        profile.value.user.dateOfBirth = '1900-01-01';
      }
      let saveUrl = "/users/profile/create";
      if (profileOptions.value?.invite) saveUrl = "/users/profile/save/invite";
      loading.value = true;
      API.post(saveUrl, {
        ...profile.value,
        companyID: profileOptions.value?.companyID ?? company.value.companyID,
        accountID: accountID.value ?? profile.value.user.accountID
      }).then(() => {
        loading.value = false;
        toast({
          text: "User created successfully"
        });
        handleClose();
      }).catch(err => {
        loading.value = false;
        console.error(err);
        if (err.response.data == 'Duplicate Email') {
          toast({
            text: err?.response?.data?.msg ?? "Email already exists",
            type: "error"
          });
        } else {
          toast({
            text: err?.response?.data?.msg ?? "Something went wrong while adding user",
            type: "error"
          });
        }
      });
    };
    const submitUser = async () => {
      loading.value = true;
      API.post("/users/profile/save", {
        ...profile.value,
        companyID: company.value.companyID
      }).then(() => {
        loading.value = false;
        if (profileOptions.value?.invite) {
          toast({
            text: `User has been added for ${company.value?.companyName} and an invite email has been sent`
          });
          return handleClose();
        }
        toast({
          text: "User created successfully"
        });
        handleClose();
      }).catch(err => {
        loading.value = false;
        console.error(err);
        toast({
          text: "Something went wrong while saving user",
          type: "error"
        });
      });
    };
    return {
      tabs,
      loading,
      passwordVisible,
      profileOpen,
      userID,
      profileOptions,
      profile,
      userSchema,
      ethnicities,
      countries,
      userTitles,
      assignableRoles,
      rolesToSelectFrom,
      company,
      selectLookupItem,
      userRef,
      physicalAddress,
      postalAddress,
      createNewUser,
      submitUser,
      accountID,
      hasRoles,
      handleClose,
      foreign
    };
  }
};